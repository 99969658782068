import * as ReactRedux from 'react-redux'
import React from 'react'

import * as locks from '@rushplay/compliance/locks'

import * as session from '../session'
import * as player from '../player'
import * as CombinedSelectors from '../combined-selectors'

import { MainMenu as Component } from './main-menu'

export function MainMenu(props) {
  const authenticated = ReactRedux.useSelector(state =>
    session.isAuthenticated(state.session)
  )
  const balanceCents = ReactRedux.useSelector(state =>
    player.getBalanceCents(state.player)
  )
  const isLocksInitialized = ReactRedux.useSelector(state =>
    locks.isInitialized(state.locks)
  )
  const hasLocksActive = ReactRedux.useSelector(state =>
    locks.hasLocks(state.locks)
  )
  const createdAt = ReactRedux.useSelector(state =>
    session.getCreatedAt(state.session)
  )
  const storeEnabled = ReactRedux.useSelector(state =>
    CombinedSelectors.isInventoryStoreEnabled(state)
  )

  return (
    <Component
      authenticated={authenticated}
      hasLowBalance={balanceCents < 1000}
      locked={isLocksInitialized && hasLocksActive}
      loggedInAt={authenticated ? new Date(createdAt) : null}
      storeEnabled={storeEnabled}
      {...props}
    />
  )
}

// For @loadable/components
export default MainMenu
